html, body, #root{
    width:100%;
    height:100%;
    margin:0;
    font-family: 'Poppins';
}
#root {
    display: flex;
    flex-direction: column;
}
/*main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}*/
main .leaflet-container {
    flex:1;
    height: 100%;
    margin-left: 0;
    left: 0;
    position: relative;
    outline: none;
    width:100vw;
    transition: all 0.5s;
    background:#010010 !important;
}
.stopencja {
    
    color: #dcdcdc;
    font-size: 12px;
    line-height: 12px;
    text-align: justify;
    margin: 0 20px;
    margin-bottom: 10px;
}

body.drawer-opened-desktop main .leaflet-container {
    /*margin-*/left: min(29vw, 565px);
    width: calc(100vw - min(29vw, 565px));
}
body {
    background-color: var(--main-bg-color);
}
.header-container {
    z-index: 2;
}
.alert-container {
    padding: 6px 16px;
    line-height: 1.43;
    display: flex;
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);
    border-radius: 4px;
    letter-spacing: 0.01071em;
}
.alert-container.success {
    background-color: #4caf50;
    color: white;
}
.alert-message {
    padding: 8px 0;
    display: flex;
    align-items: center;
}
.alert-icon {
    color: #ff9800;
    width: 1em;
    height: 1em;
    display: flex;
    opacity: 0.9;
    padding: 7px 0;
    font-size: 22px;
    margin-right: 12px;
}
.alert-icon svg {   
    fill: currentColor;
    width: 100%;
    height: 100%;
}
.alert-container.success .alert-icon svg {   
    color: white;
}
.btn {
    border-radius: 15px !important;
    background-color: var(--active-color) !important;
    color: var(--inactive-color) !important;
    padding-left: 37px !important;
    padding-right: 37px !important;
    min-width: 139px !important;
    margin-left:12.5px !important;
    margin-right:12.5px !important;
    font-family: Poppins !important;
    font-weight:500 !important;
}
.main-container .leaflet-container .leaflet-map-pane .leaflet-pane img, .main-container .leaflet-container .leaflet-map-pane .leaflet-pane div{
    outline: none !important;
}
.leaflet-bar {
    box-shadow: none !important;
    border: none !important;
}
.btn.yellow-btn {
    
    background-color: var(--btn-special-background) !important;
    color: var(--btn-special-text) !important;
}
.login-link {
    text-decoration: none;
    color:white;
    font-weight:400;
}
.login-btn{
    width: 451px;
    max-width:100%;
    min-height: 40px;
    border-radius: 20px !important;
    color:white !important;
    margin-top:25px !important;
    font-size:16px !important;
    text-transform: uppercase !important;
    background-repeat: no-repeat;
    background-position: 28px center;
    background-size: 23px;
    font-family: Poppins !important;
    font-weight:500 !important;
}
.finds-title{
    font-family: Poppins !important;
    text-transform: uppercase;
    font-size: 19px !important;
    color: #ffffff !important;
    display: flex;
}
#founds-box {
    transition: height 0.7s;
    overflow: hidden;
}
#founds-box.show-finds {
    height:180px;   
}
#founds-box.hide-finds {
    height:55px;   
}
#my-markers-box {
    transition: height 0.7s;
    overflow: hidden;
}
#my-markers-box.show-markers {
    height:140px;   
}
#my-markers-box.hide-markers {
    height:55px;   
}
#my-finds label:first-child {
    margin-top:12px;
}
#my-markers label:first-child {
    margin-top:12px;
}
.login-btn.battlenet-btn{
    background-color: #42aefb;
    background-image: url('./loginbuttons/battlenet.png');
}
.login-btn.steam-btn{
    background-color: black;
    background-image: url('./loginbuttons/steam.png');
}
.login-btn.google-btn{
    background-color: #db3236;
    background-image: url('./loginbuttons/google.png');
}
.login-btn.facebook-btn{
    background-color: #29487d;
    background-image: url('./loginbuttons/facebook.png');
}
.form-container {
    display: flex;
    flex-direction:column;
}
.modal-marker-container .form-container {
    margin-left: 87px;
    margin-right: 87px;
    margin-top:30px;
}
div[aria-labelledby="edit-marker-modal"]{
    display:flex;
    justify-content: center;
}
.subcategory-icon {
    width: 33px;
    margin-right:19px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.subcategory-icon img {
    
    max-width: 26px;
    max-height: 26px;
}
.input-marker, .modal-marker-container .form-container label {
    color: var(--main-bg-color) !important;
    font-family: Lato !important;
    font-size: 17px !important;
    font-weight: 300 !important;
}
.form-container > label > span {
    color: var(--main-bg-color) !important;
    font-family: Lato;
    font-size: 18px;
}
.subcategory-title-container {
    flex:1;
}
.subcategory-title-container, .category-title-container {
    cursor: pointer;
    font-size: 16px;
    margin: 0;
    font-weight: unset;
    margin-right: 10px;
}
.subcategory-title-container, .category-title-container, .subcategory-count {
display: flex;
align-items: center;
word-break: normal;
}
.subcategory-count {
    margin-right: 12px;
}
.beta-container {
    color: white !important;
    font-size: 10px !important;
    font-family: "Poppins" !important;
    letter-spacing: 2px !important;
}

.privacypolicy, .privacypolicy a {
    color: white !important;
    font-size: 10pt;
}
a + .privacypolicy {
    
    margin-top:30px;
}
.vote-container{
    display:flex;
    align-items: center;
}
.vote-up, .vote-down{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    font-weight:400;
    font-size:20px;
    justify-content: center;
    background-repeat: no-repeat;
    background-size:contain;
    background-position: center;
    padding-left: 8px;  
    padding-right: 8px;  
}
.vote-up {
    background-image: url('./vote-up-inactive.png');
}
.vote-up.active {
    background-image: url('./vote-up-active.png');
}
.vote-down {
    background-image: url('./vote-down-inactive.png');
}
.vote-down.active {
    background-image: url('./vote-down-active.png');
}
.vote-up.active, .vote-down.active{
    font-weight: 700;
}
.input-fieldset {
    border-radius: 14px !important;
    border: 1px solid #1a203680 !important;
}
.input-fieldset.empty {
    border-color: #b90000 !important;
}
.toggle-finds {
    margin-left: auto;
    cursor: pointer;
}
.clickable {
    
    cursor: pointer;
}
.popup-content-container{
    display:flex;
    flex-direction:row;
}
.popup-marker-data {
    flex: 5;
    font-size: 14px;
    font-family: Lato;
    color: var(--marker-text);
}
.popup-vote-container {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
}
.button-container{
    display:flex;
    flex-direction:column;
}
.button-container button{
    flex:1;
}
.side-filters {
    position: fixed;
    right: 20pt;
    top: 70pt;
    z-index: 400;
    background-color: var(--header-color);
}
.filter-box {
    transition: height 0.7s;
    overflow: hidden;
    background-color: var(--header-color) !important;
    box-shadow: 0 20px 35px 11px var(--header-shadow-color);
}
.filter-box > div {
    margin:15pt;
    width: 160px;
}
.leaflet-popup-content-wrapper {
    background-color: var(--marker-background);
    color: var(--marker-text);
}
.leaflet-popup-content-wrapper > .leaflet-popup-content {
    width: 300px !important;
    margin-left:25px;
    margin-right:25px;
    margin-top:35px;
    margin-bottom:35px;
}
.input-file {
    display:none;
}
.marker-title {
    font-size: 25px;
    font-family: Poppins;
    font-weight: 600;
    color: var(--marker-text);
    margin-top:0;
    margin-bottom:0;
}
.hide-mf-popup{
    display: none !important;
}
.points-container {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    color: var(--active-color);
    border-bottom: 1px solid #0a0d1633;
    padding-bottom: 15px;
    margin-bottom: 10px;
}
.switch-checked-dot{
    color:var(--main-bg-color) !important
}
.switch-checked-dot + span{
    background-color:var(--main-bg-color) !important
}
.avatar-profile{
    width:147px !important;
    height:147px !important;
    margin:auto;

}
.avatar-container {
    position: relative;
}
.map-menu {
    position: absolute;
    z-index: 99999;
    right: 27px;
    bottom: 27px;
    height:48px;
    display: flex;
    border-radius: 100px;
    width: 210px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-transform: uppercase;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}
.map-menu.user {
    right: 200px;
}
.map-menu .map-position {
    flex:1;
    background-color:#fff;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}
.map-menu .dropdowner .map-position {
    height:48px;
    flex: unset;
}
.map-menu > .map-position {
    border-radius: 100px;
}
.map-menu .map-position.active {
    background-color:#f3f2f2;
    font-weight: 500;
}
.iframe-message {
    display: none;
    background: #d3ccc2;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.iframe-message a {
    color: #40c1ac;
    text-decoration: none;
}
.dropdowner {
    display: none;
    border-radius:24px;
    overflow: hidden;
}
.dropdowner.visible {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    bottom: 48px;
}
.map-position svg {
    margin-left:20px;
}
body.iframe-detected .iframe-message {
    display: flex !important;
}
body.iframe-detected #root {
    display: none;
}
.category-container {
    display: flex;
    width:90%;
    margin:auto;
    flex-direction: column;
    margin-bottom:40px;
    margin-top:15px;
    flex:1;
    flex-shrink: 0;
}
.leaflet-left .leaflet-control {
    margin-left:0;
}

.england-coming-soon {
    color: #cba654;
    font-weight:500;
    letter-spacing: normal;
    font-size: 18px;
    display: none;
}
.leaflet-top .leaflet-control {
    margin-top:0;
}
.leaflet-top {
    bottom: 100px;
    top: unset;
}
.leaflet-left {
    right:20pt;
    left:unset;
}
@media (hover: none) and (pointer: coarse) {
    .leaflet-control-container {
        display:none;
    }
}
.authors {

}
.leaflet-bar a {
    border-bottom: none!important;
    width:62px !important;
    height:68px!important;
    display: flex !important;
    align-items: center;
    background-color: #cac9c9!important;
    justify-content: center;
}
.leaflet-bar a:hover {
    background-color: #bdbdbd !important;
}
.leaflet-bar a:first-child {
    border-top-left-radius: 31px!important;
    border-top-right-radius: 31px!important;
}
.leaflet-bar a:last-child {
    border-bottom-left-radius: 31px!important;
    border-bottom-right-radius: 31px!important;
}
.category-container .category-title-container {
    color: #ffffff;
    font-size: 19px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
}
.category-subs-container {
    
    display: flex;
    flex-wrap: wrap;
}
#marker-title {
    font-weight: 600;
    font-size: 40px;
    font-family: Poppins;
    text-align: center;
    padding-left:20px;
    padding-right:20px;
}
#position-marker {
    height: 41px;
    background-color: var(--normal-link-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: Lato;
    font-size: 22px;
    font-weight: 700;
    margin-top:21px;
}
#mobile-buttons-container {
    width: 466px;
    max-width: 90%;
    justify-content: center;
    flex-direction: column;
}
#mobile-buttons-container > a {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 20px;
    width:100%;
}
.subcategory-container {
    flex: 1;
    color: #ffffff;
    font-family: Lato;
    font-size: 20px;
    font-weight: 300;
    display: flex;
    margin-top:15px;
    min-width:170px;
    max-width:100%;
}
.add-button {
    width: 43px;
    height: 43px;
    background-color: #54f2f2;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
}
.toggle-active {
    background-color: var(--active-color);
    color: black !important;
}
.uhu {
    display: flex;
    justify-content: center;
}
.marker-desc{
    margin: 23px 0;
}
.marker-options-container {
    display: flex;
    justify-content: space-between;
}
.marker-options-container > div {
    
}
.copy-marker {
    margin-bottom: 13px;
}
.copy-marker, .suggestion-marker, .report-marker {
    color: var(--normal-link-color);
    font-weight: 700;
    font-family: Lato;
    font-size:14px;
}
.report-marker  {
    color: var(--report-link-color);
    text-align: right;
}
.accept-marker  {
    color: var(--accept-link-color);
    text-align: right;
}
.action-container {
    display:flex;
}
.privacypolicy span svg {
    color: white !important;
}
main.main-container{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    min-height: 100%;
    width: 100%;
    z-index: 1;
}
main.main-container.row-main{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    min-height: 100%;
    width: 100%;
    z-index: 1;
}
#desktop_drawer ~ #root {
    background-color:red;
}
#desktop_drawer .drawer-dyngs {
    background-color: var(--main-bg-color);
    border-right: none;
    height: 100vh;
    overflow-x: hidden;
    box-shadow: 0 0 145px 105px var(--main-shadow-color);
}
#mobile_drawer .drawer-dyngs-mobile {
    background-color: var(--main-bg-color);
    border-right: none;
    max-width:95vw;
    max-height:100vh;
    overflow-x: hidden;
    box-shadow: 0 0 145px 105px var(--main-shadow-color);
    z-index:999999999;
}
#mobile_drawer .drawer-dyngs-mobile div {
    
}
.active-cat span  {
    font-weight:700;
}
.non-active-cat span {

}
.active-cat {
    opacity:1;
}
.non-active-cat {
    opacity: 0.6;
}
#desktop_drawer > div {
    height: unset;
}
.finds-label > span:first-child{
    color: var(--active-color) !important;
    padding-bottom: 6px;
    padding-top: 6px;
}
.finds-label > span:nth-child(2){
    color: white !important;
    text-transform: uppercase;
    font-size: 14px !important;
    font-family: Poppins !important;
}
.modal-overall {
    position: absolute !important;
    padding-top: 4%;
    bottom: 10% !important;
    overflow: scroll;
    /* margin-bottom: 200px; */
    height: 100%;
    display: block !important;
}

#editor {
    position: absolute;
    right: 20pt;
    bottom: 20pt;
    z-index: 1000
}
#hamburger_button_desktop {
    
    display:block;
    position: absolute;
    color: white;
    z-index:9999;
    margin-top: 8px;
    transition: left 0.25s;
    left: 0;
}
body.drawer-opened-desktop #hamburger_button_desktop {
    left: calc(min(29vw, 565px) + 20px);
}
@media only screen and (max-width: 599px){
    .modal-overall {
        top: 0% !important;
    }
    .modal-marker-container {
        margin-bottom: 0px !important;
        padding-top: 20px !important;
        margin-top:10px !important;
        padding-bottom: 45px !important;
    }
    .modal-marker-container .form-container {
        margin-top: 15px !important;
    }
    #position-marker {
        margin-top:5px;
    }
    #founds-box {
        border-radius:4px 0px 4px 4px;
        right:0;
        top:56px;
        box-shadow:none;
    }
    header, header > div {
        box-shadow: none !important;
    }
    #editor {
        right: 0;
        border-radius:0;
        bottom: 0;
        width:100%;
    }
    #hamburger_button {
        margin-top: 4px;
    }
    .map-menu {
        border-radius: 0;
        width: 100%;
        right: 0 !important;
        bottom: 0;
        box-shadow: none;
    }
    .map-menu.user {
        bottom: 48px;
    }
    .map-position, .dropdowner {
        border-radius: 0 !important;
    }
}
.btn-send[disabled] {
    opacity: 0.5;
}
@media only screen and (min-width: 1039px){
    #hamburger_button {
        display:none;
    }
    #map_logo {
        margin-left:565px;
    }
    #mobile_drawer {
        display: none !important;
    }
}
@media only screen and (max-width: 1038px){
    #hamburger_button_desktop {
        display: none;
    }
    #hamburger_button {
        display:block;
        position: absolute;
        color: white;
        z-index:999;
        margin-top: 8px;
    }
    header a.btn {
        display:none;
    }
    #desktop-drawer-container, #desktop_drawer {
        display:none;
    }
    main .leaflet-container {
        margin-left:0;
        width: unset !important;
        left: 0 !important;
    }
    
    .modal-marker-container .form-container {
        margin-left: 27px;
        margin-right: 27px;
        margin-top:30px;
    }
}

@media only screen and (max-width: 599px){

    #hamburger_button {
        margin-top: 4px;
    }
}

@media only screen and (max-width: 440px){

    .login-form-container {
        align-items: flex-start !important;
    }
}
.login-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.login-form-container {
    width:100%;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;

}
.login-container > div {
    flex:1;
    height: 100%;
}
.login-container .login-side-background {
    background: var(--btn-special-background) url('./login-bg.jpg');
    background-repeat: no-repeat;
    background-size:cover;
}
.login-container .register-side-background {
    background: var(--active-color) url('./register-bg.jpg');
    background-repeat: no-repeat;
    background-size:cover;
}
.input-login {
    border: 1px solid #ffffff80 !important;
    border-radius:16px !important;
}
.input-login-label {
    color:#ffffff80 !important;
}
.input-contact {
    border: 1px solid #1b213780 !important;
    border-radius:16px !important;
}
.input-contact-label {
    color:#1b213780 !important;
}
.interactive-map-title {
    color: #ffffff !important;
    font-family: "Poppins" !important;
    font-size: 14px !important;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 8.4px !important;
}
.interactive-map-title.avatar-site {
    margin-bottom: 10px;
}
.reset-link {
    text-decoration: none  !important;
    color: #ffffff !important;
    font-family: "Lato";
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: 14px;
    text-align: right;
}
img.logo-login {
    margin: auto;
    margin-bottom: 30px;
    max-width:90%;
}
.logo-container{ 
    display:flex;
}

.custom-icon {
    background: #d12d34;
    padding: 8px;
    padding-bottom: 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.icon-bcg-color-5a52cb {
    background: #5a52cb;
}

.icon-bcg-color-5A52CB {
    background: #5a52cb;
}

.icon-bcg-color-9797de {
    background: #9797de;
}

.icon-bcg-color-2CA9D8 {
    background: #2CA9D8;
}

.icon-bcg-color-219c60 {
    background: #219c60;
}

.icon-bcg-color-7f171d {
    background: #7f171d;
}

.icon-bcg-color-cb5b61 {
    background: #cb5b61;
}

.icon-bcg-color-101e5a {
    background: #101e5a;
}

.icon-bcg-color-020202 {
    background: #020202;
}